<template>
  <div>
    <!-- start:: form wizard -->

    <div
      id="creation-spec-set"
      class="fixed_margin"
      style="font-size: 14px"
    >
      <!-- start: step 1 -->
      <div v-if="step == 1">
        <div class="row">
          <div class="col-12 mx-0">
            <validation-observer
              ref="spec_item_name"
              tag="form"
            >
              <validation-provider
                #default="{ errors }"
                name="Addition Set Name"
                rules="required"
              >

                <label for="name">Addition set name:</label>
                <b-form-input v-model="addition_set_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </validation-observer>
          </div>
        </div>
        <!-- end:: step 1 -->
      </div>
      <!-- start:: step 2 -->
      <div v-if="step == 2">
        <div
          v-if="specification_type == 'reuse'"
          class="my-2"
        >
          <div class="row  p-0 m-0">
            <div class="col-12  mx-0">
              <label for="select">Select Budget Group</label>
              <v-select
                v-model="existing_item_budget_group"
                :clearable="false"
                :options="budget_group"
                @input="clearSelects"
              />
            </div>
            <div class="col-10">
              <label for="select">Select Addition</label>
              <v-select
                v-model="existing_selected_item"
                :clearable="false"
                :options="filtered_addition_items"
                label="name"
              />
            </div>
            <div class="col">
              <b-button
                variant="outline-primary"
                class="text-primary my-2 w-100"
                @click="addSpecificationItem"
              >Add</b-button>
            </div>
            <div
              v-if="existing_selected_item!==null"
              class="col-12"
            >
              <div class="row">
                <div class="col-6">
                  <b>Name:</b>
                </div>
                <div class="col-6">
                  {{ existing_selected_item.name }}
                </div>
                <div class="col-6">
                  <b>Details for clients:</b>
                </div>
                <div class="col-6">
                  {{ existing_selected_item.details }}
                </div>
                <div class="col-6">
                  <b>Internal Note:</b>
                </div>
                <div class="col-6">
                  {{ existing_selected_item.internal_note }}
                </div>
                <div class="col-6">
                  <b>Total COGS</b>
                </div>
                <div class="col-6">
                  {{ Number(existing_selected_item.total_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                }) }}
                </div>
                <div class="col-6">
                  <b>Total Price:</b>
                </div>
                <div class="col-6">
                  {{ Number(existing_selected_item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                }) }}
                </div>
                <div class="col-6">
                  <b>Rolled up in base spec:</b>
                </div>
                <div class="col-6">
                  <div
                    v-if="existing_selected_item.is_base_spec"
                  >
                    <feather-icon
                      class="text-body"
                      icon="CheckIcon"
                      size="16"
                    />
                  </div>
                  <div
                    v-else
                  >
                    <feather-icon
                      class="text-body"
                      icon="XIcon"
                      size="16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end:: step 2 -->
      <!-- start:: step 3 -->
      <div
        v-if="step == 3"
        class=" p-0 m-0"
      >
        <h5>Addition Set {{ addition_set_name }} Summary</h5>
        <div v-if="items.filter((i) => i.budget_group == 'Construction').length > 0">
          <label
            for=""
            class="bold"
          > Construction addition</label>
          <b-table
            striped
            hover
            :items="items.filter((i) => i.budget_group == 'Construction')"
            :fields="fields"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(total_price)="data">
              {{
                Number(data.item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(total_cost)="data">
              {{
                Number(data.item.total_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(is_base_spec)="data">
              <div
                  v-if="data.item.is_base_spec"
              >
                <feather-icon
                    class="text-body"
                    icon="CheckIcon"
                    size="16"
                />
              </div>
              <div
                  v-else
              >
                <feather-icon
                    class="text-body"
                    icon="XIcon"
                    size="16"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="w-100 text-center">
                <a @click="removeItemFromSummary(data.item)">
                  <uil-times style="width: 20px; height: 20px" />
                </a>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="items.filter((i) => i.budget_group == 'FF&E').length > 0">
          <label
            for=""
            class="bold"
          > FF&E addition</label>
          <b-table
            striped
            hover
            :items="items.filter((i) => i.budget_group == 'FF&E')"
            :fields="fields"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(total_price)="data">
              {{
                Number(data.item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(total_cost)="data">
              {{
                Number(data.item.total_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(is_base_spec)="data">
              <div
                  v-if="data.item.is_base_spec"
              >
                <feather-icon
                    class="text-body"
                    icon="CheckIcon"
                    size="16"
                />
              </div>
              <div
                  v-else
              >
                <feather-icon
                    class="text-body"
                    icon="XIcon"
                    size="16"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="w-100 text-center">
                <a @click="removeItemFromSummary(data.item)">
                  <uil-times style="width: 20px; height: 20px" />
                </a>
              </div> </template></b-table>
        </div>
        <div v-if="items.filter((i) => i.budget_group == 'AV/IT').length > 0">
          <label
            for=""
            class="bold"
          > AV/IT addition</label>
          <b-table
            striped
            hover
            :items="items.filter((i) => i.budget_group == 'AV/IT')"
            :fields="fields"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(total_price)="data">
              {{
                Number(data.item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(total_cost)="data">
              {{
                Number(data.item.total_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(is_base_spec)="data">
              <div
                  v-if="data.item.is_base_spec"
              >
                <feather-icon
                    class="text-body"
                    icon="CheckIcon"
                    size="16"
                />
              </div>
              <div
                  v-else
              >
                <feather-icon
                    class="text-body"
                    icon="XIcon"
                    size="16"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="w-100 text-center">
                <a @click="removeItemFromSummary(data.item)">
                  <uil-times style="width: 20px; height: 20px" />
                </a>
              </div> </template></b-table>
        </div>
        <div v-if="items.filter((i) => i.budget_group == 'Soft Cost').length > 0">
          <label
              for=""
              class="bold"
          > Soft Cost addition</label>
          <b-table
              striped
              hover
              :items="items.filter((i) => i.budget_group == 'Soft Cost')"
              :fields="fields"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(total_price)="data">
              {{
                Number(data.item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(total_cost)="data">
              {{
                Number(data.item.total_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </template>
            <template #cell(is_base_spec)="data">
              <div
                  v-if="data.item.is_base_spec"
              >
                <feather-icon
                    class="text-body"
                    icon="CheckIcon"
                    size="16"
                />
              </div>
              <div
                  v-else
              >
                <feather-icon
                    class="text-body"
                    icon="XIcon"
                    size="16"
                />
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="w-100 text-center">
                <a @click="removeItemFromSummary(data.item)">
                  <uil-times style="width: 20px; height: 20px" />
                </a>
              </div> </template></b-table>
        </div>
      </div>
      <!-- end:: step 3 -->
      <div class="text-right my-2  p-0 mx-0">
        <b-button
          v-if="step != 1"
          variant="secondary"
          class="mx-1"
          @click="step--"
        >Go back</b-button>
        <b-button
          v-if="step == 1"
          variant="primary"
          @click="goToNextStep"
        >Define Additions Items</b-button>
        <b-button
          v-if="step == 2"
          variant="primary"
          :disabled="items.length == 0"
          @click="step++"
        >Review Specs Items</b-button><b-button
          v-if="step == 3"
          variant="primary"
          @click="saveItems"
        >
          Save
        </b-button>
      </div>
    </div>
    <!-- end:: form wizard -->
  </div>
</template>

<script>
import {
  BOverlay,
  BFormInput,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
  BInputGroup,
  BTable,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilTimes, UilGripHorizontalLine } from '@iconscout/vue-unicons'

import { required } from '@core/utils/validations/validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BOverlay,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BTable,
    BFormRadio,
    required,
    ValidationObserver,
  },
  data() {
    return {
      show: false,
      step: 1,
      budget_group: ['FF&E', 'AV/IT', 'Construction', 'Soft Cost'],
      specification_type: 'reuse',
      form: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: null,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      emptyForm: {
        budget_group: 'Construction',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: null,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      addition_set_name: '',
      existing_additions_items: [],
      selected_item: null,
      existing_selected_item: null,
      existing_item_budget_group: 'Construction',
      avit: [],
      cnst: [],
      ffe: [],
      fields: [
        {
          key: 'name',
          label: 'Spec',
          sortable: true,
        },
        {
          key: 'total_price',
          label: 'Total Price',
          sortable: false,
        },
        {
          key: 'total_cost',
          label: 'Total COGS',
          sortable: false,
        },
        {
          key: 'is_base_spec',
          label: 'Rolled up in base spec',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Remove Spec',
          sortable: true,
        },
      ],
      items: [],
      existing_additions_items_no_filter: [],
    }
  },
  computed: {
    filtered_addition_items() {
      this.existing_additions_items = this.existing_additions_items_no_filter
      if (this.items.length > 0) {
        // eslint-disable-next-line array-callback-return
        this.items.map(i => {
          this.existing_additions_items = this.existing_additions_items.filter(it => it.id !== i.id)
        })
      }
      if (this.existing_item_budget_group != null) {
        return this.existing_additions_items.filter(
          e => e.budget_group === this.existing_item_budget_group,
        )
      }
      return []
    },
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + Number(this.form.markup) / 100),
      )
    },
    total_price_w_delivery() {
      if (this.form.budget_group != 'CNST') {
        return Number(this.total_price * (1 + (this.form.unit_delivery_install / 100)))
      }
      return this.form.price_per_unit
    },

    item_attached_to_spec_item() {
      const empty = {
        id: 0,
        name: null,
        specification_swap_set_id: 0,
        budget_group: '',
        uid: null,
        construction_work_id: 0,
        unit_delivery_install: 0,
        markup: 0,
        cogs_per_unit: 0,
        price_per_unit: 0,
        manufacturer: '',
      }
      if (this.existing_selected_item != null) {
        if (this.existing_selected_item.uid == null) {
          return this.cnst.find(
            c => c.id == this.existing_selected_item.construction_work_id,
          )
        }
        let result = empty
        if (this.existing_item_budget_group == 'FF&E') {
          result = this.ffe.find(c => c.uid == this.existing_selected_item.uid)
        }
        if (this.existing_item_budget_group == 'AVIT') {
          result = this.avit.find(c => c.uid == this.existing_selected_item.uid)
        }
        return result
      }
      return empty
    },
  },
  mounted() {
    this.getBaseSpecItems()
    this.getExistingSpecItems()
  },
  methods: {
    getExistingSpecItems() {
      this.$http
        .get('/additions/items')
        .then(response => {
          const { data } = response
          this.existing_additions_items = data
          this.existing_additions_items_no_filter = data
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    getBaseSpecItems() {
      this.$http
        .get('/specifications/sets/base-spec')
        .then(response => {
          const { data } = response
          this.avit = data.avit
          this.cnst = data.cnst
          this.ffe = data.ffe
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    addExistingSpecification() {
      this.form.specifications.push(this.existing_selected_item)
      this.existing_selected_item = null
      this.showToast('success', 'Spec added')
    },
    clearSelects() {
      (this.existing_selected_item = null), (this.selected_item = null)
    },
    addSpecificationItem() {
      if (this.specification_type == 'new') {
        this.form.idx = `id${Math.random().toString(20).slice(2)}`
        this.form.id = null
        if (this.form.budget_group != 'CNST') {
          this.form.price_per_unit = this.total_price_w_delivery
          this.form.uid = this.selected_item.uid
          this.form.construction_work_id = null
        } else {
          this.form.construction_work_id = this.selected_item.id
          this.form.uid = null
          this.form.unit_delivery_install = 0
          this.form.markup = 0
        }
        this.items.push(this.form)
        this.form = { ...this.emptyForm }
        this.selected_item = null
        this.showToast('success', 'Spec added')
      } else {
        this.existing_selected_item.idx = `id${Math.random().toString(20).slice(2)}`
        this.items.push(this.existing_selected_item)
        this.existing_selected_item = null
        this.showToast('success', 'Spec added')
      }
    },
    removeItemFromSummary(item) {
      if (this.items.length > 1) {
        this.items = this.items.filter(i => i.idx != item.idx)
      }
      this.filtered_addition_items
    },
    saveItems() {
      this.$emit('save', {
        name: this.addition_set_name,
        status: 'Unpublished',
        items: this.items,
      })
      this.step = 1
      this.addition_set_name = ''
      this.items = []
      this.form = { ...this.emptyForm }
      this.clearSelects()
    },
    goToNextStep() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_name.validate().then(success => {
          if (success) {
            this.step = 2
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
label {
  font-size: 14px;
}
.fixed_margin{
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 15px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
